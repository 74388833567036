var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('input',{attrs:{"type":"password","name":"pwd","autocomplete":"new-password","hidden":""}}),_c('b-row',[(_vm.actionType !== 'EDIT')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"prepend":String(_vm.prefix).toLowerCase()}},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Account Name'))+" ")]),_c('validation-provider',{attrs:{"name":"Account Name","rules":"required|min:1|max:16|alpha-num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":((String(_vm.prefix).toLowerCase()) + "@")}},[_c('b-form-input',{ref:"inputUsername",attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Account Name'),"autocomplete":"off"},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2620103090)})],1)],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Account Name'))+" ")]),_c('validation-provider',{attrs:{"name":"Account Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{ref:"inputUsername",attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Account Name'),"autocomplete":"off","disabled":""},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Contact')))]),_c('validation-provider',{attrs:{"name":"Contact","rules":"min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Contact')},model:{value:(_vm.formData.contact),callback:function ($$v) {_vm.$set(_vm.formData, "contact", $$v)},expression:"formData.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Password')))]),_c('validation-provider',{attrs:{"name":"Password","rules":_vm.passwordRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Password'),"type":"password","autocomplete":"off"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(_vm.actionType!=='EDIT')?_c('b-col',[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":""},model:{value:(_vm.formData.requireChangePassword),callback:function ($$v) {_vm.$set(_vm.formData, "requireChangePassword", $$v)},expression:"formData.requireChangePassword"}},[_c('span',{staticClass:"h5 font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Require ChangePassword')))])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }