<template>
  <!-- form -->
  <validation-observer ref="simpleRules">
    <b-form autocomplete="off">
      <!-- disable auto fill -->
      <input
        type="password"
        name="pwd"
        autocomplete="new-password"
        hidden
      >

      <b-row>
        <b-col
          v-if="actionType !== 'EDIT'"
          md="6"
        >
          <b-form-group
            :prepend="String(prefix).toLowerCase()"
          >
            <label class="font-weight-bolder">
              {{ $t('Account Name') }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="Account Name"
              rules="required|min:1|max:16|alpha-num"
            >
              <b-input-group
                :prepend="`${String(prefix).toLowerCase()}@`"
              >
                <b-form-input
                  ref="inputUsername"
                  v-model="formData.username"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('Account Name')"
                  autocomplete="off"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <b-form-group>
            <label class="font-weight-bolder">
              {{ $t('Account Name') }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="Account Name"
            >
              <b-input-group>
                <b-form-input
                  ref="inputUsername"
                  v-model="formData.username"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('Account Name')"
                  autocomplete="off"
                  disabled
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Contact') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Contact"
              rules="min:1|max:16"
            >
              <b-form-input
                v-model="formData.contact"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Contact')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Password') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Password"
              :rules="passwordRules"
            >
              <b-form-input
                v-model="formData.password"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Password')"
                type="password"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="actionType!=='EDIT'"
        >
          <b-form-checkbox
            v-model="formData.requireChangePassword"
            class="custom-control-success"
            switch
          >
            <span class="h5 font-weight-bolder">{{ $t('Require ChangePassword') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'

import '@validations'
import vSelect from 'vue-select'
import store from '@/store'
import { get } from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    prefix: {
      type: String,
      required: true,
    },

    // CREATE, EDIT
    actionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        username: '',
        contact: '',
        password: '',
        requireChangePassword: true,
      },
    }
  },
  computed: {
    passwordRules() {
      if (this.actionType !== 'EDIT') {
        return 'required|min:6|max:16|password'
      }
      return 'min:6|max:16|password'
    },
  },
  mounted() {
    // localize('th')
  },
  methods: {
    async validationForm() {
      return this.$refs.simpleRules.validate()
    },
    getFormData() {
      return this.formData
    },
    setFormData(data) {
      this.formData = { ...this.formData, ...data }
    },
    formReset() {
      this.$refs.simpleRules.reset();
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = ''
      })

      this.$nextTick(() => {
        this.$refs.inputUsername.focus()
      })
    },
  },
}
</script>
